import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PageRoot from '../../components/common-components/templates/page-root-v2';
import Works from '../../components/work-v2/index';
import colorPaletteV2 from '../../styles/color-palette-v2';
import '../../styles/foundation-v2.css';
import WorkFluidImages from '../../images/gatsby-image/v2-work-page/fluid-images';
import WorkDetailFluidImages from '../../images/gatsby-image/work-detail-page/fluid-images';
import pagesV2 from '../../constants-v2/pages-v2';
import SEOHeaderV2 from '../../components/common-components/templates/page-root-v2/seo-v2';

const wpContentSideMarginSp = 2.4;

const captionWidthPc = '608rem';
const captionWidthSp = `calc(100% - ${wpContentSideMarginSp * 2}rem)`;

const contentFontH = {
  sp: {
    h1: {
      font: '24rem',
      line: '36rem',
    },
    h2: {
      font: '24rem',
      line: '36rem',
    },
    h3: {
      font: '21rem',
      line: '31.5rem',
    },
    h4: {
      font: '17rem',
      line: '27rem',
    },
  },
  pc: {
    h1: {
      font: '24rem',
      line: '34.75rem',
    },
    h2: {
      font: '24rem',
      line: '34.75rem',
    },
    h3: {
      font: '18rem',
      line: '24.06rem',
    },
    h4: {
      font: '16rem',
      line: '23.17rem',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
`;

const BodyWrapper = styled.div`
  z-index: 1;
  margin-bottom: 160px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const CaptionRightAlign = styled(Link)`
  display: flex;
  align-items: center;
  width: 110rem;
  margin: 0 0 24rem auto;
  @media (max-width: 1023px) {
    margin-bottom: 18rem;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24rem;
  }
`;

const TopAttention = styled.h2`
  font-weight: 400;
  color: ${colorPaletteV2.blue};
  @media (max-width: 1023px) {
    font-size: 16rem;
    line-height: 16rem;
  }
  @media (min-width: 1024px) {
    font-size: 16rem;
    line-height: 16rem;
    margin-left: 4rem;
  }
`;

const WorkContentWrapper = styled.div`
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
  }
`;

const EyeCatchPic = styled(Img)`
  border: 0;
  background-size: cover;
  @media (max-width: 1023px) {
    width: ${captionWidthSp};
    height: 320rem;
    margin: 0 auto 24px;
  }
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
    height: 420px;
    margin-bottom: 28rem;
  }
  @media (max-width: 640px) {
    height: 210rem;
  }
`;

const WorkTitle = styled.h1`
  font-size: 24rem;
  line-height: 34.75rem;
  font-weight: 700;
  @media (max-width: 1023px) {
    margin-bottom: 12rem;
  }
  @media (min-width: 1024px) {
    margin-bottom: 14rem;
  }
`;

const WorkTags = styled.div`
  @media (max-width: 1023px) {
    display: block;
    line-height: 28.8rem;
  }
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    margin-bottom: 40rem;
  }
`;

const WorkTagsItem = styled.span`
  font-size: 16rem;
  color: ${colorPaletteV2.blue};
  display: inline;
  margin-right: 5rem;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const WorksOuter = styled.div`
  margin-bottom: 100px;
`;

const WorkDescriptionWrapper = styled.div`
  font-size: 16px;
  font-size: 16rem;
  line-height: 28.8px;
  line-height: 28.8rem;

  & h2 {
    padding: 2rem 8rem;
    border-left: solid 6px ${colorPaletteV2.blue};
    margin-top: 20px;
    margin-top: 20rem;
    margin-bottom: 20px;
    margin-bottom: 20rem;

    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h2.font};
      line-height: ${contentFontH.sp.h2.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h2.font};
      line-height: ${contentFontH.pc.h2.line};
    }
  }
  & a {
    color: ${colorPaletteV2.blue};
    &:link,
    &:visited,
    &:hover {
      color: ${colorPaletteV2.blue};
    }
  }
`;

const WorkParagraph = styled.p`
  font-size: 16px;
  font-size: 16rem;
  line-height: 28.8px;
  line-height: 28.8rem;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 24px;
`;

const WorkBlockImagePC = styled(Img)`
  @media (min-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const WorkBlockImageSP = styled(Img)`
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const DxHumanIntroduce = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="実績紹介｜人材を弊社でお預かりしてDX技術者（エンジニア）として育成！" />
      <Root className="wrapper-v2">
        <BodyWrapper>
          <CaptionRightAlign to={pagesV2.works.path}>
            <TopAttention>実績紹介一覧</TopAttention>
          </CaptionRightAlign>
          <WorkContentWrapper>
            <EyeCatchPic fluid={WorkFluidImages('dxHumanIntroduce')} />
            <WorkTitle>
              人材を弊社でお預かりして DX技術者（エンジニア）として育成！
            </WorkTitle>
            <WorkTags>
              <WorkTagsItem>#IT人材育成</WorkTagsItem>
              <WorkTagsItem>#内製化</WorkTagsItem>
            </WorkTags>
            <WorkDescriptionWrapper>
              <h2>プロジェクトの背景</h2>
              {/* 20210329 TO DO:「同社」の表現考えてもらう */}
              <WorkParagraph>
                &emsp;小型から大型のものまで発電所や化学プラント向けの超高圧バルブを製造する岡野バルブ製造株式会社様は、次の収益の柱となる事業の種をいくつか蒔いていましたが、思ったスピードで事が運ばないという課題感を抱えていました。
                <br />
                その原因の一つがITエンジニアの不在で、これまでは弊社のような外部の企業に委託して開発を進めてきましたが、スピード感を持って事業開発を推進するには、開発を内製化する必要があると同社経営層は考えていました。
                <br />
                そこで、次世代バルブメンテナンス事業の開発でパートナーシップを組んでいた弊社に人材開発も任せていただくことになりました。
              </WorkParagraph>
              <WorkBlockImagePC
                fluid={WorkDetailFluidImages('dxHumanIntroduceDescktop01')}
              />
              <WorkBlockImageSP
                fluid={WorkDetailFluidImages('dxHumanIntroduceMobile01')}
              />

              <h2>取り組み内容</h2>
              <WorkParagraph>
                &emsp;将来は50人規模のIT技術者チームを組成するという目標の下、まずは同社内で2名社員の方を選出してもらい、弊社にて1年間OJTでIT教育を受けてもらいました。
                <br />
                2名中、1名は中堅社員で50人を束ねるチームリーダー候補として、もう１名は新卒１年目でチームの主要メンバー候補として、毎日肩を並べて弊社社員と共同開発をしながら技術の基礎からみっちり学んでもらいました。
                <br />
                ORCLというバルブ診断装置との連携も可能にし、同装置で取得したバルブの診断結果をアプリケーションにアップロードすることで、バルブのリアルな「健康状態」をクラウド上で一元管理することが可能になりました。
                <br />
              </WorkParagraph>
              <WorkParagraph>
                Amazon&nbsp;Web&nbsp;Servicesのようなクラウド環境や、Ruby&nbsp;on&nbsp;Railsを使い実際のシステムを開発し実践的な知識と技術を習得すると同時に、技術プレゼンテーションや技術ドキュメント作成等のビジネススキルも磨いてもらいました。{' '}
                <br />
                1年の育成期間が終わる頃には、新機能の開発から問題を特定してデバッグする作業まで、基礎的な事が1人で完結できるようになりました。
              </WorkParagraph>
              <WorkParagraph>
                DX技術者育成プログラムの詳細はこちらです。事業変革の実現に課題を感じている方はぜひご覧ください。
                <a href="https://www.regnio.co.jp/dx-hr-development/">
                  https://www.regnio.co.jp/dx-hr-development
                </a>
              </WorkParagraph>
            </WorkDescriptionWrapper>
          </WorkContentWrapper>
        </BodyWrapper>
        <WorksOuter>
          <Works />
        </WorksOuter>
      </Root>
    </PageRoot>
  );
};
export default DxHumanIntroduce;
